






















































import { Component, Vue, Ref, Prop } from 'vue-property-decorator'
import Step1 from './Step1/index.vue'
import Step2 from './Step2/index.vue'
import Step3 from './Step3/index.vue'
import Step4 from './Step4/index.vue'
import { chatImportStore } from '@/store'

interface Step {
  name: number
  icon: string
  activeIcon: string
  doneIcon: string
  title: string
  caption: string
  component: any
}

@Component({
  components: {
    BaseStepper: () => import('@/components/UI/BaseStepper/index.vue'),
    IconX: () => import('@tada/icons/dist/IconX.vue'),
    IconMaximize: () => import('@/components/UI/icons/IconMaximize.vue'),
    IconMinimize: () => import('@/components/UI/icons/IconMinimize.vue'),
  },
})
export default class ImportChatDialog extends Vue {
  @Ref() private readonly stepper!: { next: () => void }

  @Prop({
    type: Boolean,
  }) private readonly smallMode!: boolean

  private step = 1

  get steps (): Step[] {
    return [{
      name: 1,
      title: this.$t('import.steps.load').toString(),
      caption: this.$t('import.steps.load').toString(),
      icon: '1',
      activeIcon: '1',
      doneIcon: '1',
      component: Step1,
    }, {
      name: 2,
      title: this.$t('import.steps.check').toString(),
      caption: this.$t('import.steps.check').toString(),
      icon: '2',
      activeIcon: '2',
      doneIcon: '2',
      component: Step2,
    }, {
      name: 3,
      title: this.$t('import.steps.import').toString(),
      caption: this.$t('import.steps.import').toString(),
      icon: '3',
      activeIcon: '3',
      doneIcon: '3',
      component: Step3,
    }, {
      name: 4,
      title: this.$t('import.steps.done').toString(),
      caption: this.$t('import.steps.done').toString(),
      icon: '4',
      activeIcon: '4',
      doneIcon: '4',
      component: Step4,
    }]
  }

  private async created (): Promise<void> {
    await chatImportStore.actions.loadState()
    this.step = chatImportStore.getters.step
  }

  private closeDialog (): void {
    chatImportStore.actions.closeChatImportDialog()
  }

  private get tooltip () {
    return {
      text: this.$t('import.closeTitle').toString(),
      options: {
        backgroundColor: 'var(--bg-background)',
        color: 'var(--font-text)',
      },
    }
  }
}
