




















































import { Vue, Component } from 'vue-property-decorator'
import { chatImportStore } from '@/store'

@Component({
  components: {
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    BaseLinearProgress: () => import('@/components/UI/BaseLinearProgress.vue'),
  },
})
export default class Step2 extends Vue {
  private async created () {
    await chatImportStore.actions.loadState()
  }

  get fileName () {
    return chatImportStore.state.fileName
  }

  get action () {
    return chatImportStore.getters.getProcessingActionByType('archive_unpacking')
  }

  get title () {
    return this.isHaveError ? this.action?.message ?? this.$t('import.step2.titleError').toString()
      : this.unpackingDone
        ? this.$t('import.step2.title', { fileName: this.fileName }).toString()
        : this.$t('import.step2.title', { fileName: this.fileName }).toString()
  }

  get description () {
    return this.isHaveError
      ? this.action?.body ?? this.$t('import.step2.descriptionError').toString()
      : this.unpackingDone
        ? this.$t('import.step2.descriptionDone').toString()
        : this.$t('import.step2.description').toString()
  }

  get progress () {
    return chatImportStore.getters.unpackingProgress / 100
  }

  get isHaveError () {
    return this.action?.hasError
  }

  get unpackingDone () {
    return chatImportStore.getters.unpackingProgress >= 100
  }

  private async cancel () {
    await chatImportStore.actions.removeArchive()
    this.$emit('goTo', 1)
  }
}
