



































import { Vue, Component, Ref } from 'vue-property-decorator'
import { chatImportStore } from '@/store'

import { format } from 'quasar'
const { humanStorageSize } = format

@Component({
  components: {
    Description: () => import('./Description.vue'),
    DropZone: () => import('./DropZone.vue'),
    LoadingZone: () => import('./LoadingZone.vue'),
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
  },
})
export default class Step1 extends Vue {
  @Ref() private readonly dropzone!: { uploadFile: () => void }
  private file: File | null = null

  private upload (file: File) {
    this.file = file
    chatImportStore.actions.uploadFile(file)
  }

  private get fileName () {
    return this.file?.name ?? ''
  }

  private get progressValue () {
    return chatImportStore.state.loadingProgress
  }

  private get fileSize () {
    return chatImportStore.state.fileSize
  }

  private get loadingSize () {
    return chatImportStore.state.loadingSize
  }

  private get progressLabel () {
    return this.$i18n.t('import.step1.processLabel', {
      loadingSize: humanStorageSize(this.loadingSize),
      fileSize: humanStorageSize(this.fileSize),
    }).toString()
  }

  private get isLoadDone () {
    return chatImportStore.state.isLoadDone
  }

  private checkData () {
    this.$emit('next')
  }

  private get loading () {
    return chatImportStore.state.isLoading || this.isLoadDone
  }

  private get hasError () {
    return chatImportStore.state.hasLoadError
  }

  private repeatUpload () {
    this.dropzone.uploadFile()
  }
}
