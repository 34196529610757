











































import { Component, Vue } from 'vue-property-decorator'
import { chatImportStore, teamsStore } from '@/store'

@Component({
  components: {
    IconUploadFinish: () => import('@/components/UI/icons/IconUploadFinish.vue'),
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    GenerationProgress: () => import('./GenerationProgress.vue'),
  },
})
export default class Step4 extends Vue {
  private isFinish = false
  private openChat () {
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: this.chatId,
      },
    })
    chatImportStore.actions.closeChatImportDialog()
  }

  get chatName () {
    return chatImportStore.state.chatName
  }

  get chatId () {
    return chatImportStore.getters.getProcessingActionByType('generate_chat')?.message ?? ''
  }

  private async goTo () {
    await chatImportStore.actions.clearLoading()
    this.$emit('goTo', 1)
  }

  get isGeneration () {
    return chatImportStore.state.parserState === 'generating' && !this.isFinish
  }

  private finish () {
    this.isFinish = true
    this.$emit('stepDone')
  }
}
