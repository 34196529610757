



















































import { Component, Vue } from 'vue-property-decorator'
import { MappedUser, Contact } from '@tada-team/tdproto-ts'
import { chatImportStore } from '@/store'

@Component({
  components: {
    Informer: () => import('./Informer.vue'),
    ChatInfo: () => import('./ChatInfo.vue'),
    MappedUsers: () => import('./MappedUsers.vue'),
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    IconInfo: () => import('@/components/UI/icons/IconInfo.vue'),
  },
})
export default class Step3 extends Vue {
  private users: MappedUser[] = []

  private async created () {
    await chatImportStore.actions.loadMappedUsers()
    this.users = this.mappedUsers
  }

  get mappedUsers () {
    return chatImportStore.state.mappedUsers
  }

  get processedLabel () {
    const total = this.users.length
    const count = this.selectedUsersCount
    return this.$t('import.step3.processedLabel', { count, total })
  }

  get selectedUsersCount () {
    return this.users.filter(u => !!u.contact).length
  }

  get hasError () {
    return !!Object.keys(this.duplicatedUsers).length
  }

  get duplicatedUsers () {
    const duplicated: { [key: string]: { contact: Contact, count: number } } = {}
    this.users.forEach(user => {
      this.users.forEach(u => {
        if (u.contact?.jid === user.contact?.jid && user.externalUserId !== u.externalUserId) {
          const jid = user.contact?.jid
          if (!jid || !user.contact) return
          duplicated[jid] = {
            count: (duplicated[jid]?.count ?? 0) + 1,
            contact: user.contact,
          }
        }
      })
    })
    return duplicated
  }

  get errorLabel () {
    const key = Object.keys(this.duplicatedUsers)[0]
    const count = this.duplicatedUsers[key]?.count ?? 0
    const name = this.duplicatedUsers[key]?.contact.displayName ?? ''
    return this.$t('import.step3.errorLabel', { count, name })
  }

  private matchUsers () {
    chatImportStore.actions.matchUsers(this.users)
  }

  private async generateChat () {
    await chatImportStore.actions.generateChat()
    this.next()
  }

  get canGenerateChat () {
    return !this.hasError && this.selectedUsersCount === this.users.length
  }

  private next () {
    this.$emit('goTo', 4)
  }

  private async cancel () {
    await chatImportStore.actions.removeArchive()
    this.$emit('goTo', 1)
  }
}
